import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const HintModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Tipps" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        <a href="https://botsin.space/@hpwordlehints" > Wenn du einen Tipp benötigst, tippe auf diesen Text.</a>
      </p>
    </BaseModal>
  )
}
