export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Gut gemacht!', 'Klasse!', 'Glückwunsch!']
export const GAME_COPIED_MESSAGE = 'Spiel in die Zwischenablage kopiert'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Zu wenige Buchstaben'
export const WORD_NOT_FOUND_MESSAGE = 'Wort nicht gefunden'
export const HARD_MODE_ALERT_MESSAGE =
  'Der schwere Modus kann nur vor Spielstart aktiviert werden!'
export const HARD_MODE_DESCRIPTION =
  'Alle aufgedeckten Hinweise müssen beachtet werden!'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'Für bessere Sichtbarkeit'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Das Wort war: ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Du musst ${guess} in position ${position} verwenden`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Wort muss ${letter} beinhalten`
export const ENTER_TEXT = 'OK'
export const DELETE_TEXT = '<--'
export const STATISTICS_TITLE = 'Statistiken'
export const GUESS_DISTRIBUTION_TEXT = 'Übersicht'
export const NEW_WORD_TEXT = 'Neues Wort in'
export const SHARE_TEXT = 'Teilen'
export const SHARE_FAILURE_TEXT =
  'Unable to share the results. This feature is available only in secure contexts (HTTPS), in some or all supporting browsers.'
export const MIGRATE_BUTTON_TEXT = 'Transfer'
export const MIGRATE_DESCRIPTION_TEXT =
  'Click here to transfer your statistics to a new device.'
export const TOTAL_TRIES_TEXT = 'Gesamtversuche'
export const SUCCESS_RATE_TEXT = 'Erfolgsquote'
export const CURRENT_STREAK_TEXT = 'Aktuelle Serie'
export const BEST_STREAK_TEXT = 'Beste Serie'
export const DISCOURAGE_INAPP_BROWSER_TEXT =
  "Du verwendest einen eingebetteten Browser. Für die beste Erfahrung verwende (generell) einen separaten Broweser."

export const DATEPICKER_TITLE = 'Wähle ein vergangenes Datum'
export const DATEPICKER_CHOOSE_TEXT = 'Auswählen'
export const DATEPICKER_TODAY_TEXT = 'Heute'
export const ARCHIVE_GAMEDATE_TEXT = 'Spieldatum'
