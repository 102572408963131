import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const DatenschutzModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Datenschutzerklärung" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
      Nach §13 TMG
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">

Anbieter
</p>
<p className="text-sm text-gray-500 dark:text-gray-300">
Diese Website wird angeboten von
</p>
<p className="text-sm text-gray-500 dark:text-gray-300">
Alexander Welsing
</p>
<p className="text-sm text-gray-500 dark:text-gray-300">
Grevener Straße 140
</p>
<p className="text-sm text-gray-500 dark:text-gray-300">
48159 Münster
</p>
<p className="text-sm text-gray-500 dark:text-gray-300">
datenschutz@a-wels.de
</p>
<p className="text-sm text-gray-500 dark:text-gray-300">
017634235534
</p>
<p className="text-sm text-gray-500 dark:text-gray-300">
Gesammelte Daten
</p>
<h2 className="text-sm text-gray-500 dark:text-gray-300">
Plausible Analytics
</h2>
<p className="text-sm text-gray-500 dark:text-gray-300">
Erfassung von allgemeinen Daten und Informationen Die Internetseite hp.a-wels.de erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Hierfür wird die DSGVO konforme Lösung 'Plausible' verwendet. Die entsprechende Datenschutzerklärung ist unter folgendem Link einsehbar: https://plausible.io/data-policy.
</p>
<h2 className="text-sm text-gray-500 dark:text-gray-300">
Logfiles</h2>
<p className="text-sm text-gray-500 dark:text-gray-300">
Bei Aufruf unserer Webseite werden Logfiles gesetzt und bleiben für 15 Tage gespeichert. Diese Logfiles beinhalten den Browser Agent, die IP Adresse sowie einen Zeitstempel und sind für den Betrieb dieser Website notwendig. 
        
</p>
    </BaseModal>
  )
}
