import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const ImpressumModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Impressum" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Anbieter:
        </p>

        <p className="text-sm text-gray-500 dark:text-gray-300">

        Alexander Welsing
        </p>

        <p className="text-sm text-gray-500 dark:text-gray-300">

        Grevener Straße 140
        </p>

        <p className="text-sm text-gray-500 dark:text-gray-300">

        48159 Münster
        </p>

        <p className="text-sm text-gray-500 dark:text-gray-300">

        Kontakt:
        </p>

        <p className="text-sm text-gray-500 dark:text-gray-300">

        Telefon: +4917634235534      
        </p>

        <p className="text-sm text-gray-500 dark:text-gray-300">

        E-Mail: hp@a-wels.de
        </p>

        <p className="text-sm text-gray-500 dark:text-gray-300">

        Website: a-wels.de
        </p>

    </BaseModal>
  )
}
