import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Anleitung" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">

        Errate das Wort in 6 Versuchen. Nach jedem Versuch verrät dir die Farbe der Felder, wie nah du am gesuchten Wort dran bist.
        Ersetze umläute (Ä = AE, ß = ss)
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="A"
          status="correct"
        />
        <Cell value="L" isCompleted={true} />
        <Cell value="B" isCompleted={true} />
        <Cell value="U"isCompleted={true} />
        <Cell value="S" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Der Buchstabe A ist an der richtigen Stelle.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="S" isCompleted={true} />
        <Cell value="N" isCompleted={true} />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="A"
          status="present"
        />
        <Cell value="P" isCompleted={true} />
        <Cell value="E" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Der Buchstabe A kommt im Wort vor, ist aber an der falschen Stelle.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell value="L" isCompleted={true} />
        <Cell value="U" isCompleted={true} />
        <Cell value="P" isCompleted={true} />
        <Cell isRevealing={true} isCompleted={true} value="I" status="absent" />
        <Cell value="N" isCompleted={true} />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Der Buchstabe I kommt nicht im Wort vor.
      </p>

      <p className="mt-6 text-sm italic text-gray-500 dark:text-gray-300">
       Es handelt sich hierbei um eine Open-Source implementierung von einem Wortspiel, welche von cwackkerfuss erstellt wurde. Danke dafür!{' '}
        <a
          href="https://github.com/cwackerfuss/reactle"
          className="font-bold underline"
        >
          Guck dir den Code hier an
        </a>{' '}
      </p>
    </BaseModal>
  )
}
