export const WORDS = [
"dippet",
"peter",
"slughorn",
"flugstunde",
"adler",
"niffler",
"snape",
"karte",
"wichtel",
"dauerklebefluch",
"beetle",
"umbridge",
"kraeuterkunde",
"treiber",
"weisen",
"demiguise",
"horkrux",
"seidenschnabel",
"diantus",
"annehmbar",
"albus",
"black",
"minimuff",
"ravenclaw",
"finnigan",
"alastor",
"argus",
"zaubereiministerium",
"trelawney",
"ratte",
"alecto",
"trank",
"percival",
"horace",
"keller",
"diptam",
"besen",
"trimagisch",
"zentaur",
"hexe",
"begehren",
"umhang",
"federfluegel",
"nimbus",
"alohomora",
"pruefung",
"fred",
"zauberstab",
"tonks",
"heiligtuemer",
"seamus",
"luna",
"magisch",
"phineas",
"pettigrew",
"sucher",
"dolores",
"halbblut",
"sectumsempra",
"gryffindor",
"kueche",
"troll",
"hagrid",
"prinz",
"basilisk",
"norris",
"charlie",
"binns",
"wermut",
"nagini",
"gasse",
"neundreiviertel",
"krone",
"weihnachten",
"pomona",
"brief",
"schrecken",
"gilderoy",
"patronus",
"minerva",
"sirius",
"drache",
"kingsley",
"fleur",
"weide",
"geschichte",
"wurmschwanz",
"dobby",
"flammel",
"nigellus",
"werwolf",
"ginny",
"teddy",
"gaunt",
"helga",
"hermine",
"malfoy",
"dudley",
"zug",
"durmstrang",
"moody",
"hogwarts",
"phoenix",
"kamin",
"weise",
"armando",
"rowena",
"todesser",
"kedavra",
"mysteriumsabteilung",
"muggel",
"narbe",
"brown",
"tatze",
"zauber",
"lupin",
"cornelius",
"arnold",
"ron",
"wulfric",
"percy",
"godric",
"pflege",
"kammer",
"occamy",
"lavender",
"pomfrey",
"fidelius",
"zauberei",
"hauslehrer",
"imperio",
"cedric",
"turm",
"voldemort",
"hufflepuff",
"kuchen",
"kraetze",
"fenrir",
"einhorn",
"auferstehung",
"dursley",
"filch",
"jaeger",
"merope",
"carrow",
"frosch",
"tagebuch",
"blitz",
"lumos",
"kreacher",
"halloween",
"lockhart",
"medaillon",
"brian",
"bombarda",
"newt",
"note",
"crabbe",
"riese",
"quaffel",
"narzissa",
"gemeinschaftsraum",
"myrte",
"thomas",
"james",
"sphinx",
"alraune",
"longbottom",
"kuerbispastete",
"fudge",
"crucio",
"zag",
"winky",
"schwert",
"hooch",
"ligusterweg",
"stab",
"huette",
"molly",
"hippogreif",
"eberkopf",
"severus",
"klatscher",
"stein",
"draco",
"bella",
"aquamenti",
"expelliarmus",
"zauberkunst",
"serpensortia",
"animagus",
"katze",
"salazar",
"filius",
"turnier",
"dachs",
"dumbledore",
"magie",
"grindeloh",
"wuensche",
"amycus",
"hueter",
"dean",
"greif",
"doxy",
"nerhegeb",
"greyback",
"kimmkorn",
"nicolas",
"rita",
"moony",
"elderstab",
"zaubertrank",
"feuerkelch",
"hut",
"squib",
"geheimniswahrer",
"acromantula",
"potter",
"zauberschach",
"remus",
"vernon",
"kelch",
"goyle",
"firenze",
"muggelkunde",
"bohne",
"arthur",
"fuchsbau",
"orden",
"ohnegleichen",
"petunia",
"thestral",
"schrecklich",
"schlange",
"tom",
"loewe",
"pokal",
"quirrel",
"reparo",
"mies",
"flubberwurm",
"reducio",
"mcgonagall",
"krummbein",
"kreatur",
"flitwick",
"ghul",
"schnatz",
"george",
"festessen",
"apparieren",
"mundungus",
"muffliato",
"weasley",
"riddle",
"nicholas",
"fawkes",
"irrwicht",
"slytherin",
"geist",
"murtlap",
"kerze",
"bowtruckle",
"scamander",
"rumtreiber",
"delacour",
"zutaten",
"astronomie",
"bellatrix",
"digory",
"wahrsagen",
"riddikulus",
"nox",
"zonko",
"diadem",
"baron",
"prophezeiung",
"butterbier",
"eule",
"lucius",
"amnesia",
"bill",
"harry",
"grimmauldplatz",
"schokofrosch",
"sybill",
"tore",
"protego",
"avada",
"kobold",
"levicorpus",
"fig",
"rubeus",
"neville",
"knuddelmuff",
"sauberwisch",
"feuerblitz",
"stupor",
"accio",
"verwandlung",
"gnom",
"schach",
"fluffy",
"utz",
"eulerei",
"sprout",
"hauself",
"feuer",
"auto",
];